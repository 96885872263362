/* src/styles/global.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --glass-border: rgba(255, 255, 255, 0.1);
  --glass-card: linear-gradient(180deg, rgba(255, 255, 255, 0.03) 0%, rgba(255, 255, 255, 0.02) 100%);
}

body {
    font-family: 'Raleway', sans-serif;
    background-color: #0E0029;
    color: white;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-weight: 700; /* Bold */
  }
  
  p {
    font-weight: 400; /* Regular */
  }

.card-glass {
  border-radius: 16px;
  border: 1px solid var(--glass-border);
  background: var(--glass-card);
  backdrop-filter: blur(35.900001525878906px);
  margin-bottom: 20px;
  box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.05);
  transition: box-shadow 0.3s ease;
}

.card-glass:hover {
  box-shadow: 0 0 15px 0 rgba(255, 255, 255, 0.1);
}

.card-glass-header {
  border-radius: 0px 0px 16px 16px;
  border-right: 1px solid var(--glass-border);
  border-bottom: 1px solid var(--glass-border);
  border-left: 1px solid var(--glass-border);
  margin-bottom: 0px;
}

.card-glass-footer {
  border-radius: 16px 16px 0 0;
  border-top: 1px solid var(--glass-border);
  border-right: 1px solid var(--glass-border);
  border-left: 1px solid var(--glass-border);
  margin-top: 40px;
  margin-bottom: 0px;
}

body {
  background-color: #0E0029;
  color: white;
}